// Use This for testing
const URL = "tpi.insafeservices.co.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "KOTAK MAHINDRA BANK, TARSALI, VADODARA",
  "Bank A/c. No.": "1613012008",
  "IFSC Code": "KKBK0000843",
};
export const GST_NUMBER = "24ABMFA5320N1ZC";

export const DISABLED_MODULE =["CoordinatesAddress"]; 

export const plantTypes = [
  { id: 1, label: 'Branch' },
  { id: 2, label: 'Division' },
  { id: 3, label: 'Department' },
  { id: 4, label: 'SubDepartment' }
];

