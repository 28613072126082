export const companyList = [
  {
    id: 1,
    label: "ABHINAV ENGINEERING WORKS",
    address: "W-85 [A], M.I.D.C. SATPUR, NASIK-422 007.",
  },
  {
    id: 2,
    label: "INSAFE SERVICES PVT LTD",
    address:
      "G-24, ANAND PLAZA, NEAR HOTEL AMARPREET SIGNAL, JALNA ROAD, AURANGABAD - 431001",
  },
];
